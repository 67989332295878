<template>
  <v-navigation-drawer class="position-fixed sideBarContent"
                       permanent
                       app
                       width="100px">
    <v-list-item class="pa-2 mt-4">
      <img style=" border-radius: 15px" class="logoMenu" src="../../assets/logo_violet.png" alt="Logo sofa">
    </v-list-item>
    <v-list class="navMenu pa-0" dense flat>
      <v-list-item-group>
        <v-list-item class="listMenu" :to="{ name:'Home' }" active-class="active">
          <v-list-item-icon class="iconMenu d-flex align-self-center">
            <v-icon large>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item class="listMenu" :to="{ name:'User' }" active-class="active">
          <v-list-item-icon class="iconMenu d-flex align-self-center">
            <v-icon large>mdi-account-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>


export default {
  name: "SideBar",
  data() {
    return {
      active: '',
      items: [
        {title: 'Dashboard', icon: 'mdi-view-dashboard-outline', path: {name: 'Home'}},
        {title: 'Account', icon: 'mdi-account-outline', path: {name: 'Profile'}},
      ],
      right: null,
    }
  },
  methods: {
    /**
     * when detect a click
     * change route
     * @param status
     * @returns {Promise<void>}
     */
    async getRoute(status) {
      await this.$router.push(status).then(() => this.$root.$emit('routeUpdated'))
    },
    /**
     * when detect route change
     * active icon who match with route name
     * @returns {Promise<void>}
     */
    activeIcon() {
      if (this.$route.name === 'dashboard') {
        this.isActive = !this.isActive;
        this.active = 'Dashboard'
      }
      if (this.$route.name === 'user') {
        this.isActive = !this.isActive;
        this.active = 'Account'
      }
    },
  },

  mounted() {
    this.$root.$on('routeUpdated', () => {
      this.activeIcon()
    })
    this.activeIcon()
  },
  async beforeDestroy() {
    this.$root.$off('routeUpdated')
  },

}
</script>

<style lang="scss" scoped>
.sideBarContent {

  ::v-deep {
    .logoMenu {
      width: 70%;
      height: 10%;
      margin: 0 auto;

      img {
        margin: 5% 0;
        width: 80%;
      }
    }
  }

  ::v-deep {
    .navMenu {
      display: flex;
      justify-content: center;

      .listMenu:hover {
        cursor: pointer;
        background-color: RGBA(121, 7, 72, 0.3);
        border-left: 3px solid RGBA(121, 7, 72, 0.7);
      }

      .active {
        background-color: #ffffff !important;
        border-left: 3px solid RGBA(121, 7, 72, 0.7);
      }

      .listMenu {
        margin-top: 40%;

        .iconMenu {
          margin: 0 auto;

          .v-icon:after {
            background-color: transparent !important;
          }
        }
      }
    }
  }

}
</style>
